var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加场景",
            "close-on-click-modal": false,
            top: "5%",
            visible: _vm.showDialog,
            width: "40%",
            "label-width": "80px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.brandChange },
                      model: {
                        value: _vm.addform.brandId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "brandId", $$v)
                        },
                        expression: "addform.brandId",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brandName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店", prop: "hotelId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.hotelChange },
                      model: {
                        value: _vm.addform.hotelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "hotelId", $$v)
                        },
                        expression: "addform.hotelId",
                      },
                    },
                    _vm._l(_vm.hotelSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "房型", prop: "roomTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.roomTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "roomTypeId", $$v)
                        },
                        expression: "addform.roomTypeId",
                      },
                    },
                    _vm._l(_vm.roomTypeSelectData, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.typeName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "语音类型", prop: "applianceType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.applianceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "applianceType", $$v)
                        },
                        expression: "addform.applianceType",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "开关", value: "SWITCH" } },
                        [_vm._v("开关")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "场景", value: "SCENE_TRIGGER" } },
                        [_vm._v("场景")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "窗帘", value: "CURTAIN" } },
                        [_vm._v("窗帘")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "纱帘", value: "CURT_SIMP" } },
                        [_vm._v("纱帘")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "场景名称", prop: "sceneName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addform.sceneName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "sceneName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.sceneName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "面板ID", prop: "sceneId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addform.sceneId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "sceneId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.sceneId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "按键位置", prop: "loopNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addform.loopNo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "loopNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.loopNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }